

* {
  font-family: 'Work Sans', sans-serif !important;
  margin: 0px;
  padding: 0px;
}

button,
input {
  outline: none;
}

button:focus {
  outline: none;
}
::ng-deep button:focus {
  outline: none;
}

button:enabled{
  cursor: pointer;
}

.confirm-button {
  background-color: var(--dark-green);
  color: #ffffff;
  border-radius: 30px;
  border-color: transparent;
  text-align: center;
}
.page {
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  padding-top: 70px;
  overflow: auto;
}
.text-button {
  border: none;
  background: none;
}
.options-panel {
  border-radius: 10px 10px 0px 0px;
}

.custom-dialog-no-padding .mat-dialog-container {
  padding: 0px !important;
  max-width: 800px;
}

mat-icon{
  font-family: "Material Icons" !important;
}
