:root {
  --wrebit-green: #a4d065;
  --darker-wrebit-green: #8bb74c;
  --extra-extra-dark-grey: #494949;
  --extra-dark-grey: #7c7c7c;
  --dark-grey: #e1e1e1;
  --light-grey: #fcfcfc;
  --light-blue: #d9e4eb;
  --grey-blue: #abc0cf;
  --dark-blue: #3c79a5;
  --darker-blue: #23608c;
  --green-blue: #3e8d9b;
  --light-green: #eaf0ee;
  --dark-green: #54b88e;
  --darker-green: #44a27b;
  --correct: #83c87c;
  --error: #ba4d44;
  --white: #ffffff;
  --mdc-checkbox-selected-icon-color: #ffffff;
  --mdc-checkbox-selected-checkmark-color: #ffffff;
}
