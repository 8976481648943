/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  height: 100%;
}
body {
  margin: 0;
}

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * skolar-latin:
 *   - http://typekit.com/eulas/00000000000000003b9b29bc
 *   - http://typekit.com/eulas/00000000000000003b9b29c2
 *   - http://typekit.com/eulas/00000000000000003b9b29bb
 *   - http://typekit.com/eulas/00000000000000003b9b29c1
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-06-29 14:43:52 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=tsc7pzr&ht=tk&f=27792.27795.27799.27810&a=21575761&app=typekit&e=css");
//@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: "skolar-latin";
  src: url("https://use.typekit.net/af/c35e63/00000000000000003b9b29bc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c35e63/00000000000000003b9b29bc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c35e63/00000000000000003b9b29bc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "skolar-latin";
  src: url("https://use.typekit.net/af/c7f73e/00000000000000003b9b29c2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c7f73e/00000000000000003b9b29c2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c7f73e/00000000000000003b9b29c2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "skolar-latin";
  src: url("https://use.typekit.net/af/361b31/00000000000000003b9b29bb/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/361b31/00000000000000003b9b29bb/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/361b31/00000000000000003b9b29bb/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "skolar-latin";
  src: url("https://use.typekit.net/af/927a49/00000000000000003b9b29c1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/927a49/00000000000000003b9b29c1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/927a49/00000000000000003b9b29c1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

.tk-skolar-latin {
  font-family: "skolar-latin", serif !important;
}

/* Importing Bootstrap SCSS file. */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

button.mat-button{
  background-color: var(--white);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--dark-green);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: var(--dark-green);
  background-color: var(--dark-green);
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: var(--dark-green);
}
